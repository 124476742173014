@font-face {
    font-family: arial-rg;
    src: url(../public/fonts/arial.ttf);
}

@font-face {
    font-family: arial-bd;
    src: url(../public/fonts/arial-bd.ttf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: arial-rg, sans-serif, -apple-system,BlinkMacSystemFont;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,h2,h3,h4,h5,h6,strong {
    font-family: arial-bd, sans-serif, -apple-system,BlinkMacSystemFont;
    font-weight: 500;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

main {
    margin: 0 auto;
    max-width: 1080px;
    border: solid 1px #e5e5e5;
    position: relative;
}

main .banner img {
    width: 100%;
}

main .form-section {
    width: 100%;
}

main .form-section form {
    margin: 100px auto !important;
    max-width: 600px;
    padding: 0px 20px;
}

main .form-section form h2 {
    text-align: center;
    font-size: 40px;
}

main .form-section form h2+span {
    text-align: center;
    display: block;
    font-size: 40px;
    margin-bottom: 40px;
}

main .form-section .main-form-section {
    width: 100%;
}

main .form-section .main-form-section .form-item {
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin-bottom: 30px;
}


main .form-section .main-form-section .form-item .field {
    width: 100%;
}

main .form-section .main-form-section .form-item .field input {
    width: 100%;
    padding: 10px 18px;
    border: solid 2px #007f73;
    font-size: 26px;
    outline: none;
}

main .form-section .main-form-section .form-item .label h4,
main .form-section .main-form-section .form-item .label span {
    font-size: 26px;
    max-width: 80px;
    line-height: 1;
    text-wrap: nowrap;
}

main .form-section .main-form-section button {
    font-size: 18px;
    padding: 8px 55px;
    border-radius: 12px;
    border: none;
    margin: 0 auto !important;
    display: block;
    font-weight: 600;
    font-family: arial-bd, sans-serif, -apple-system,BlinkMacSystemFont;
    background: #007f73;
    cursor: pointer;
    transition-duration: .3s;
}

main .form-section .main-form-section button span:first-child {
    font-size: 22px;
}

main .form-section .main-form-section button span {
    color: #fff;
    display: block;
}

main .form-section form * {
    color: #007f73;
}

main .form-section .main-form-section .form-item .label span {
    font-size: 34px;
}


main .form-section .main-form-section input[type="submit"]:hover {
    background: #005e55;
}

main .form-section .main-form-section .form-item .field input:focus {
    border-color: #01564e;
}

main .terms-and-condition {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    padding: 0px 60px;
    margin-bottom: 70px;
}

main .terms-and-condition .col ul {
    padding-inline: 20px;
    margin-top: 15px;
}

main .image-center {
    width: 500px;
    margin: 0 auto 50px;
    min-height: 500px;
}

main .image-center img {
    width: 100%;
}

main .terms-and-condition .arb.col h4,
main .terms-and-condition .arb.col h1,
main .terms-and-condition .arb.col ol,
main .terms-and-condition .arb.col ol li,
main .terms-and-condition .arb.col ol li * {
    text-align: right;
    direction: rtl;
}

main .terms-and-condition .arb.col ol li {
    margin-bottom: 10px;
    font-size: 18px;
}

main .terms-and-condition .eng.col ol li {
    margin-bottom: 10px;
}

main .terms-and-condition .col h4 {
    font-size: 26px;
    color: #000000;
}

main .terms-and-condition .col ul li {
    font-size: 18px;
    margin-bottom: 15px;
    color: #000000;
}


main .footer img {
    width: 100%;
}

main .footer a img {
    width: 67px;
}

main .footer .top-icons {
    display: flex;
}

main .footer .social-icons {
    text-align: center;
    display: flex;
    justify-content: center;
}

main .footer .social-icons>div {
    margin: 0px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

main .footer .top-icons+span {
    font-size: 28px;
    margin-top: 10px;
    display: block;
    font-weight: 500;
    font-family: arial-bd, sans-serif, -apple-system,BlinkMacSystemFont;
}

main .footer a {
    margin: 0px 5px;
    transition-duration: .1s;
}

main .footer a:hover {
    transform: scale(1.1);
}

main .not-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 1080px;
    height: 100vh;
}

main .not-found-wrapper .title {
    font-size: 34px;
    color: #007f73;
}

main .form-section .main-form-section .form-item .field .error {
    color: red;
    font-size: 20px;
    margin-top: 5px;
    display: block;
}

main .form-section .main-form-section .form-item .field .error p {
    color: red;
}

.api-error {
    font-size: 24px;
    text-align: center;
    margin-bottom: 50px;
}

.api-error p {
    color: red !important;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(204, 204, 204, 0.41);
}

.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #007f73;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}

@media(max-width: 767px) {
    main .form-section form {
        margin: 50px 0px;
    }

    main .form-section form h2 {
        font-size: 30px;
    }

    main .form-section form h2+span {
        font-size: 30px;
        margin-bottom: 20px;
    }

    main .image-center {
        width: 100%;
        min-height: 300px;
    }

    main .terms-and-condition {
        grid-template-columns: 1fr;
        padding: 0px 30px;
        grid-gap: 50px;
    }

    main .terms-and-condition .col.eng ol {
        padding-right: 0px;
    }

    main .terms-and-condition .col.arb ol {
        padding-left: 0;
    }

    main .terms-and-condition > *:first-child {
        order: 2;
    }
    main .terms-and-condition > *:last-child {
        order: 1;
    }

    main .footer a img {
        width: 38px;
    }

    main .footer .top-icons+span {
        font-size: 20px;
    }

    main .form-section .main-form-section .form-item {
        grid-template-columns: 1fr 3fr;
    }

    main .form-section .main-form-section .form-item .label h4,
    main .form-section .main-form-section .form-item .label span {
        font-size: 20px;
        text-wrap: wrap;
    }

    main .form-section form h2 {
        font-size: 24px;
    }

    main .form-section form h2+span {
        font-size: 24px;
    }

    main .form-section .main-form-section .form-item .field input {
        padding: 7px 18px;
        font-size: 24px;
    }

    main .form-section form {
        margin: 50px 0px !important;
    }

    main .footer .social-icons>div {
        margin: 0px 10px 30px;
    }

    main .not-found-wrapper .title {
        font-size: 20px;
    }
}